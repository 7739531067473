import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const RevGuidesTeamBuilding: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team Building</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_teams.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Reverse:1999 Team Building</h1>
          <h2>
            Everything you need to know about building teams in Reverse: 1999 -
            both early and meta teams.
          </h2>
          <p>
            Last updated: <strong>29/01/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          If you're looking for current meta teams database, check the guide
          below. In this guide we will only go over the main rules that you
          should have in mind when creating a team.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={2}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team Database"
            link="/re1999/teams-database"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_raid.jpg"
                alt="Team Database"
              />
            }
          />
        </Row>
        <SectionHeader title="Introduction" />
        <p>
          In this guide, we offer a few options and examples of pre-made teams
          and how to unleash their potential.{' '}
          <strong>
            Please take note that these are merely guidelines, and not hard
            rules.
          </strong>{' '}
          Reverse: 1999 has nearly endless options and variations when it comes
          to team building, especially if we take cyclical endgame content like
          UTTU Challenge and Limbo who are constantly bringing new mechanics
          into play. These are also only the most common “carries”, or Main
          Damage Dealers. If you don’t see a character you like here, that
          doesn’t mean they’re useless; just check the{' '}
          <strong>
            <Link to="/re1999/tier-list">Tier List</Link>
          </strong>{' '}
          or the specific character page to see their role and tags, and adjust
          the team as needed in case you want to use a specific character or
          don’t have one of the suggested characters for the combos below.
        </p>
        <p>
          If you’re still lost on some of the terms, we highly recommend you
          check the <strong>[Beginner Guide]</strong> first.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner guide"
            link="/re1999/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/reverse/categories/category_beginner.png"
                alt="Beginner guide"
              />
            }
          />
        </Row>
        <p>For a short review on how to build a team:</p>
        <ul>
          <li>
            <strong>Pick a main damage dealer</strong>. The stage will usually
            say the recommended Afflatus to use, and you can also check the
            enemy info as well as their Mental and Reality DEF.
          </li>
          <li>
            <strong>Pick a support.</strong> A support in this case refers to
            someone who will make your main damage dealer shine even more.
            Sometimes they can even be a secondary damage dealer, but often will
            be someone who also has some utility within their kit, be it in the
            shape of buffs, debuffs, crowd-control or other special effects
            (such as Moxie gain, or deplete Moxie from enemies).
          </li>
          <li>
            <strong>
              Pick either a second support or a Survival character.
            </strong>{' '}
            At first, healing won’t be a priority, but the further the game
            progresses, the trickier it may become to keep you characters alive.
            Most healers and shielders also offer some utility or damage, thus
            completing Reverse:1999 main triad of roles.
          </li>
        </ul>
        <h5>What about the fourth slot?</h5>
        <p>
          The Substitute slot (or in some stages the fourth character in battle)
          is not used often, as the ideal is that your characters don’t die. You
          can use this slot to help raise the bond of a character (either for
          the lore or for the extra clear drops) or you can pick one of the
          alternative options already suggested in the teams below. We also have
          added a list of Flex characters who can come in handy for most
          situations and to replace others. You can use that as a reference when
          deciding who to bring for your fourth slot or if you’re missing one or
          more of the characters suggested in the pre-made teams.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
      </div>
    </DashboardLayout>
  );
};

export default RevGuidesTeamBuilding;

export const Head: React.FC = () => (
  <Seo
    title="Team Building | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about building teams in Reverse: 1999 -both early and meta teams."
    game="reverse"
  />
);
